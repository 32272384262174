<template>
<div>
  <ValidationObserver ref="observer">
    <b-form   
      @submit.prevent="onSubmit"
    >
    <b-card no-body class="mb-2">
      <b-card-header class="align-items-center">

        <h4 class="mr-auto mb-0">تفاصيل الزبون</h4>
      </b-card-header>
      <b-card-body>
        <b-card-text>
          <b-row>
            <b-col cols="12" md="6">
              <BTextInputWithValidation
                rules="required"
                name="اسم الزبون"
                label="سم الزبون"
                placeholder="ادخل اسم الزبون"
                v-model="customerDetails.name"
              />
            </b-col>
            <b-col cols="12" md="6">
              <label class="mt-1">صورة </label>
              <fileDragDrop
                @inValidFile="unValidThumbImage"
                @uploaded="uploadThumbImage"
                id="employee-photo"
                height="100px"
                type="image"
                title="صورة جديدة"
              />
              <img
                :src="!customerDetails.logoDto.base64 ? customerDetails.logoDto.path.replace('~', $store.getters['app/domainHost']) : customerDetails.logoDto.base64"
                style="max-height: 320px; object-fit: fill;"
                class="w-100"
              />
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col>
            <div class="d-flex">
              <b-button
                class="mr-1"
                variant="primary"
                style="max-width:100px"
                type="submit"
                @click="updateCustomer(customerDetails)"
                >تعديل</b-button
              >
              <b-button
                type="button"
                to="../"
                variant="outline-primary"
                style="max-width:100px"
                >تراجع</b-button
              >
            </div>
          </b-col>
          <b-col
            style="display: flex;
            justify-content: flex-end;"
          >
            <b-button type="button" style="max-width:100px" variant="outline-primary"
            @click="deleteItemCustomer(id)"
              >حذف</b-button
            >
          </b-col>
        </b-row>
      </b-card-footer>
    </b-card>
     </b-form>
  </ValidationObserver>
</div>
</template>

<script>
import BTextInputWithValidation from "@core/components/inputs/BTextInputWithValidation";
import { ValidationObserver } from "vee-validate";
import {mapActions, mapGetters} from "vuex";
  import fileDragDrop from "@core/components/file-drag-drop/file-drag-drop.vue";

export default {
  props: {
    lang: String,
    id: String,
  },
  components: {
    fileDragDrop,
    BTextInputWithValidation,
    ValidationObserver,
   
  },
  computed: {
    ...mapGetters(["customerDetails"])
  },
  created() {
    this.getCustomerById({type: 3, id: this.id})
  },
  methods: {
    ...mapActions(["getCustomerById","updateCustomer","deleteItemCustomer"]),
     onSubmit() {
        this.$refs.observer.validate().then((success) => {
          if(success && this.customerDetails.logoDto.base64) {
             this.updateActivities({
              id: 0,
              name:this.customerDetails.name,
              logoDto: {
              id: 0,
              path: null,
              base64: this.customerDetails.logoDto.base64
            }
            });
          }
        })
    },
    uploadThumbImage(file) {
        this.customerDetails.logoDto.base64 = file[0].base64;
    },
    unValidThumbImage() {
      console.log("un valid Thumb file");
    },
    
  },
};
</script>
